

<template>
    <div>
        <div class="das_top_cont">
            <div class="history_box">
                <div class="title flex_center_between_box">
                    <div class="title_txt flex_center_start_box">
                        <img src="../../assets/img/account/portfolio.png" />
                        My portfolio
                    </div>
                </div>
                <div class="das_top_cont">
                    <ul class="das_top_list flex_center_between_box" style="margin-bottom: 30px">
                        <li class="das_top_item">
                        
                            <div class="item_img color1">
                                <img src="../../assets/img/head_switch_company_ic.png" />
                            </div>
                            <!-- <div class="item_title">collected</div> -->
                            <div class="item_group">
                                <div class="item_txt">{{ companyCollectedTotal }}</div>
                                <label class="item_lab">Total company data collected</label>
                             
                            </div>
                     
                        </li>
                        <li class="das_top_item">
                           
                            <div class="item_img color2">
                                <img src="../../assets/img/tianjiadaozuhe.png" />
                            </div>
                            <!-- <div class="item_title">collected</div> -->
                        
                            <div class="item_group">
                                <div class="item_txt">{{ companySavedTotal }}</div>
                                <label class="item_lab">Total companies saved in my portfolio</label>
                              
                            </div>
                        </li>
                        <li class="das_top_item">
                           
                           <div class="item_img color3">
                               <img src="../../assets/img/shoucangzu.png" />
                           </div>
                           <!-- <div class="item_title">collected</div> -->
                       
                           <div class="item_group">
                               <div class="item_txt">{{ pageChild.total }}</div>
                               <label class="item_lab"> My portfolio group</label>
                             
                           </div>
                       </li>
                    </ul>
                </div>

                <div class="right_cont_box">
                    <download-excel class="export-excel-wrapper" ref="exportExcel" :data="DetailsFormlist" :fields="json_fields" :header="title" name="portfolio.xls">
                        <el-button :disabled="splicelist.length > 0 ? false : true" style="float: right; margin-bottom: 15px" class="btn_blue flex_center" @click="exportData">
                            <img src="../../assets/img/search/export_ic1.png" />
                            <span style="position: relative; top: -3px">Export</span>
                        </el-button>
                    </download-excel>
                    <el-button :disabled="splicelist.length > 0 ? false : true" style="float: right; margin-bottom: 15px; margin-right: 15px" class="btn_blue flex_center" @click="selectsplice">
                        <!-- <img src="../../assets/img/detail/btn_ic5.png" /> -->
                        <i class="el-icon-delete"></i>
                        Delete
                    </el-button>
                    <div class="top_tab">
                        <div class="table_box">
                            <el-table ref="UsageList" :data="IndicatorsOptions" border style="width: 100%" @expand-change="handleExpandChange" @selection-change="handleSelectionChange">
                                <!-- 空数据状态的插槽 -->
                                <!-- <template slot="empty">No Data</template> -->
                                <el-table-column type="selection"></el-table-column>
                                <el-table-column type="expand">
                                    <template slot-scope="scope">
                                        <div class="tree_table_box" style="padding-top: 0px">
                                            <div class="flex_center_between_box" style="padding-top: 15px; padding-bottom: 15px">
                                                <div class="title_txt flex_center_start_box" style="width: 440px">
                                                    <label style="display: flex; width: 150px" for="">Find Company</label>
                                                    <!-- <el-autocomplete class="inline-input" v-model="CategoryVal" :fetch-suggestions="querySearch" placeholder="Company Name"></el-autocomplete> -->
                                                    <el-input class="title-input" clearable suffix-icon="el-icon-search" v-model="seachinput" label="" placeholder="Search any Keyword in this portfolio" @change="inputcompany()"></el-input>
                                                </div>
                                                <div class="flex_box">
                                                    <el-button :disabled="chinldsplicelist.length > 0 ? false : true" style="margin-right: 15px" class="btn_blue flex_center" @click="childrenselectsplice">
                                                        <!-- <img src="../../assets/img/detail/btn_ic5.png" /> -->
                                                        <i class="el-icon-delete"></i>
                                                        Delete
                                                    </el-button>
                                                    <download-excel class="export-excel-wrapper" ref="exportExcel" :data="DetailsFormlisttwo" :fields="json_fields" :header="title" name="Portfolio.xls">
                                                        <el-button :disabled="chinldsplicelist.length > 0 ? false : true" class="btn_blue flex_center" @click="childexport">
                                                            <img src="../../assets/img/search/export_ic1.png" />
                                                            <span style="position: relative; top: -3px">Export</span>
                                                        </el-button>
                                                    </download-excel>
                                                </div>
                                            </div>
                                            <el-table :data="UsageList" border cell-class-name="" :key="scope.row.Date" @selection-change="childhandleSelectionChange">
                                                <template slot="empty">No Data</template>
                                                <el-table-column type="selection"></el-table-column>
                                                <!-- <el-table-column label="No." type="index" align="center" show-overflow-tooltip :resizable="false" />
                                                <el-table-column label="Date" prop="dateTime" align="left" show-overflow-tooltip :resizable="false" /> -->
                                                <el-table-column label="Company Name/Person Name" prop="collectionCompany" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                                    <template slot-scope="scope">
                                                        <!-- <router-link :to="{ path: '/company/companysnapshot', query: { id3a: scope.row.aaaId, companyCountry: scope.row.countryCode, companyNameEn: scope.row.companyName } }">{{ scope.row.companyName }}</router-link> -->
                                                        <span @click="setid(scope.row)">{{ scope.row.collectionCompany }}</span>
                                                    </template>
                                                </el-table-column>

                                                <el-table-column label="Location" width="200" prop="" align="left" show-overflow-tooltip :resizable="false">
                                                    <template slot-scope="scope">
                                                        <div style="width: 180px" class="line_1">
                                                            {{ scope.row?.es?.city_en }}
                                                            <span class="tbl_dot" v-show="scope.row?.es?.province_en && scope.row?.es?.province_en != ' ' && scope.row?.es?.province_en != '' && scope.row?.es?.city_en != '' && scope.row?.es?.city_en != ' ' && scope.row?.es?.city_en">,</span>
                                                            {{ scope.row?.es?.province_en }}
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column label="Industry" prop="isic_en" align="left" show-overflow-tooltip :resizable="false">
                                                    <template slot-scope="scope">
                                                        <div style="width: 280px" class="line_1">
                                                            {{ scope.row?.es?.isic_en }}
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                                <!-- <el-table-column label="User name" width="200" prop="userName" align="left" show-overflow-tooltip :resizable="false" /> -->
                                                <el-table-column label="Size" width="80" :prop="'scale_en'" align="left" show-overflow-tooltip :resizable="false">
                                                    <template slot-scope="scope">
                                                        <div>
                                                            {{ scope.row?.es?.scale_en }}
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                                <el-table-column label="Status" width="80" :prop="'status_en'" align="right" show-overflow-tooltip :resizable="false">
                                                    <template slot-scope="scope">
                                                        <div>
                                                            {{ scope.row?.es?.status_en }}
                                                        </div>
                                                    </template>
                                                </el-table-column>
                                            </el-table>
                                            <div class="page_i_box">
                                                <Page :total="page.total" @onPageChange="onPageChange" @currtentPageChange="currtentPageChange"></Page>
                                                <div class="search_rsult_txt">{{ page.pageNo }}-{{ page.pageSize }} of over {{ page.total | FilterNum }} results</div>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="File Name" prop="" align="left" show-overflow-tooltip :resizable="false" class-name="company_link">
                                    <template slot-scope="scope">
                                        <div>
                                            {{ scope.row.groupName }}
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Date Created" prop="createTime" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Created By" prop="userName" align="left" show-overflow-tooltip :resizable="false" />
                                <el-table-column label="Number of Companies" prop="companyNum" align="left" show-overflow-tooltip :resizable="false" />
                                <!-- <el-table-column label="My tag" prop="groupName" align="left" show-overflow-tooltip :resizable="false" /> -->
                            </el-table>
                            <div class="page_i_box">
                                <Page :totalFont="true" :total="pageChild.total" :pageSize="pageChild.pageSize" @onPageChange="onChildPageChange" @currtentPageChange="currtentChildPageChange"></Page>
                                <div class="search_rsult_txt line_2">{{ pageChild.pageNo }}-{{ pageChild.pageSize }} of over {{ pageChild.total | FilterNum }} results for "Portfolio"</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Page from '@/components/page';
import { getCollectionList, getPortfolioCount } from '../../api/userApi';
import { selectGroup, delGroup, delCollection } from '@/api/searchApi.js';
import { mixins1 } from '@/mixins/index';

export default {
    components: { Page },
    mixins: [mixins1],
    data() {
        return {
            value1: '',
            CategoryVal: '',
            IndicatorsVal: '',
            CountryVal: '',
            pageNo: 1,
            pageSize: 10,
            checkAll: false,
            exportDialog: false,
            checkedCompanyList: [],
            noMore: false, // 控制滚动禁用
            routeLoad: false, // 控制滚动禁用
            companyList: [],
            DetailsForm: [],
            companyTblList: [],
            // 下载
            title: 'Portfolio',
            json_fields: {
                'Company Name': 'collectionCompany',

                'Date.': 'dateTime',
                userName: 'userName',
                Province: 'es.province_en',
                City: 'es.city_en',
                Industry: 'es.isic_en',
                'Inc.Yr.': 'es.incorporation_year',
                'Company Status': 'es.status_en',
                'Company Type': 'es.company_type_en',
                'Company Size': 'es.scale_en',
            },
            filterName: '',
            grouid: '',
            IndicatorsOptions: [],
            IndicatorsOption: [],
            timesDetailDialog: false, //List of login duration and times 弹框
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            pageChild: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            tabActiveName: 'CompanyName',

            UsageList: [],

            detailObj: {
                titleDate: '2022-11',
                results: [
                    { Date: '2022/11/14', LoginDuration: '10h', NumberOfLogins: '1' },
                    { Date: '2022/11/14', LoginDuration: '12h', NumberOfLogins: '1' },
                    { Date: '2022/11/14', LoginDuration: '10h', NumberOfLogins: '1' },
                ],
            },
            currentExpandedRow: null,
            splicelist: [],
            isMessageShown: false,

            DetailsFormlist: [],
            seachinput: '',
            chinldsplicelist: [],
            childsaveid: '',
            checchildkexportlist: [],
            DetailsFormlisttwo: [],
            companySavedTotal: '',
            companyCollectedTotal: '',
        };
    },
    mounted() {
        this.getCollectiondata();
    },

    methods: {
        // 子表格导出
        childexport() {
            this.DetailsFormlisttwo = this.checchildkexportlist;
        },
        // inpuytseachcompany
        async inputcompany() {
            const param = {
                page: this.page.pageNo,
                limit: this.page.pageSize,
                collectionCompany: this.seachinput,
                groupId: this.currentExpandedRow.id,
            };
            let res = await getCollectionList(param);
            if (res.code) {
                this.UsageList = res.data.rows;
                this.page.total = res.data.total;
                // this.IndicatorsOption = res.data.rows;
            } else {
                this.UsageList = [];
            }
        },
        // children删除
        childrenselectsplice() {
            if (this.chinldsplicelist.length > 0) {
                delCollection(this.chinldsplicelist).then(res => {
                    if (res.code) {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                            // duration: 5000,
                        });
                        const param = {
                            page: this.page.pageNo,
                            limit: this.page.pageSize,
                            collectionCompany: this.CategoryVal,
                            groupId: this.childsaveid,
                            startTime: this.value1[0],
                            endTime: this.value1[1],
                        };
                        getCollectionList(param).then(res => {
                            if (res.code) {
                                this.UsageList = res.data.rows;
                                this.page.total = res.data.total;
                                this.IndicatorsOption = res.data.rows;
                            } else {
                                this.UsageList = [];
                            }
                        });
                        getPortfolioCount().then(res => {
                            if (res.code) {
                                this.companyCollectedTotal = res.data.companyCollectedTotal;
                                this.companySavedTotal = res.data.companySavedTotal;
                            }
                        });
                        // this.getCollectiondata()
                    }
                });
            }
        },
        // 子事件选中
        childhandleSelectionChange(selection) {
            this.checchildkexportlist = [];
            // console.log(`selection`, selection);
            this.checchildkexportlist = selection;
            this.chinldsplicelist = [];
            selection.map(item => {
                this.chinldsplicelist.push(item.id);
            });
            this.childsaveid = selection[0]?.groupId;
        },
        // 收藏导出事件
        exportsave() {
            const param = {
                page: this.page.pageNo,
                limit: 100,
            };
            // console.log(`his.$refs.exportExcel`, this.$refs.exportExcel);
            const promises = this.splicelist.map(item => {
                return getCollectionList({ groupId: item, ...param });
            });

            let DetailsForm = [];

            Promise.all(promises)
                .then(responses => {
                    responses.forEach(res => {
                        if (res.code) {
                            // console.log(res.data.rows);
                            DetailsForm.push(...res.data.rows);
                        }
                    });
                    // 在Promise.all的回调函数中进行导出操作
                    // this.exportData();
                    this.DetailsForm = DetailsForm;
                    // this.exportData();
                })
                .catch(error => {
                    console.error(error);
                });
        },

        exportData(data) {
            this.DetailsFormlist = this.DetailsForm;
        },
        //   删除事件
        selectsplice() {
            if (this.splicelist.length > 0) {
                this.splicelist.forEach(item => {
                    delGroup({ id: item }).then(res => {
                        if (res.code) {
                            if (!this.isMessageShown) {
                                this.$message({
                                    message: res.msg,
                                    type: 'success',
                                    // duration: 5000,
                                });
                                this.isMessageShown = true;
                            }
                        }
                    });
                });

                this.getCollectiondata();

                // this.$message({
                //                     message: res.msg,
                //                     type: 'success',
                //                     // duration: 5000,
                //                 });
            }
        },
        // 全选事件
        handleSelectionChange(selection) {
            this.splicelist = [];
            selection.map(item => {
                this.splicelist.push(item.id);
            });

            this.exportsave();
        },
        // 展开行
        async handleExpandChange(row, expanded) {
            if (expanded) {
                if (this.currentExpandedRow !== row) {
                    // 收起之前展开的行
                    if (this.currentExpandedRow) {
                        this.$refs.UsageList.toggleRowExpansion(this.currentExpandedRow, false);
                    }
                    this.page.pageNo = 1;
                    this.page.pageSize = 10;
                    // 更新当前展开的行
                    this.seachinput = '';
                    this.chinldsplicelist = [];
                    this.currentExpandedRow = row;
                    // console.log(expanded);
                    const param = {
                        page: this.page.pageNo,
                        limit: this.page.pageSize,
                        // companyName: this.CategoryVal,
                        groupId: row.id,
                        startTime: this.value1[0],
                        endTime: this.value1[1],
                    };
                    let res = await getCollectionList(param);
                    if (res.code) {
                        this.UsageList = res.data.rows;
                        this.page.total = res.data.total;
                        this.IndicatorsOption = res.data.rows;
                    } else {
                        this.UsageList = [];
                    }
                }
            } else {
                // 收起当前展开的行
                this.currentExpandedRow = null;
            }
        },
        hasLetter(str) {
            const reg = /[a-zA-Z]/; // 匹配英文字母
            return reg.test(str);
        },
        setid(row) {
            // console.log(this.hasLetter(row.crefoNo));
            if (this.hasLetter(row.crefoNo)) {
                // let routeData = this.$router.resolve({
                //     path: '/users/background?pid=' + row.crefoNo + '&name=' + row.collectionCompany + '&companyCountry=' + row.countryCodeEn + '&information=Search Person',
                // });
                // window.open(routeData.href, '_blank');
            } else {
                let routeData = this.$router.resolve({
                    path: '/company/companysnapshot?id3a=' + row.crefoNo + '&companyCountry=' + row.countryCodeEn,
                });

                window.open(routeData.href, '_blank');
            }
            // sessionStorage.setItem('company', JSON.stringify(row));
        },
        querySearch(queryString, cb) {
            var IndicatorsOptions = this.IndicatorsOption;

            IndicatorsOptions.map(item => {
                item.value = item.collectionCompany;
            });
            var results = queryString ? IndicatorsOptions.filter(this.createFilter(queryString)) : IndicatorsOptions;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            // console.log(queryString);
            return IndicatorsOptions => {
                // console.log(IndicatorsOptions);
                return IndicatorsOptions.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
            };
        },
        async getCollectiondatatwo() {
            const param = {
                page: this.page.pageNo,
                limit: this.page.pageSize,
                collectionCompany: this.seachinput,
                groupId: this.currentExpandedRow.id,
                startTime: this.value1[0],
                endTime: this.value1[1],
            };
            let res = await getCollectionList(param);
            if (res.code) {
                this.UsageList = res.data.rows;
                this.page.total = res.data.total;
                this.IndicatorsOption = res.data.rows;
            } else {
                this.UsageList = [];
            }
        },
        async getCollectiondata() {
            const param = {
                page: this.pageChild.pageNo,
                limit: this.pageChild.pageSize,
                companyName: this.CategoryVal,
                groupId: this.grouid,
                startTime: this.value1[0],
                endTime: this.value1[1],
            };
            // let res = await getCollectionList(param);
            // if (res.code) {
            //     this.UsageList = res.data.rows;
            //     this.page.total = res.data.total;
            //     this.IndicatorsOption = res.data.rows;
            // } else {
            //     this.UsageList = [];
            // }
            let taglist = await selectGroup(param);
            if (taglist.code) {
                this.IndicatorsOptions = taglist.data.rows;
                this.pageChild.total = taglist.data.total;
                const user = JSON.parse(localStorage.getItem('userInfo')).userInfo;

                this.IndicatorsOptions.map(item => {
                    item.userName = user.loginName;
                });
            } else {
                this.UsageList = [];
            }
            getPortfolioCount().then(res => {
                if (res.code) {
                    this.companyCollectedTotal = res.data.companyCollectedTotal;
                    this.companySavedTotal = res.data.companySavedTotal;
                }
            });
        },
        //导出按钮
        exportResult() {
            this.pageNo = 1;
            this.pageSize = 20;
            this.getRouteList();
            this.exportDialog = true;
            // this.powerCalculate('export');
        },

        exportCompany() {
            this.DetailsForm = this.checkedCompanyList;
        },
        hanldFilterName() {
            let list = this.companyTblList;
            let keyWord = this.filterName;
            var arr = [];
            if (keyWord == '') {
                return (this.companyList = list);
            } else {
                arr = list.filter(item => {
                    return item.company_name_en.includes(keyWord);
                });
                this.companyList = arr;
            }
        },

        //分页
        onPageChange(pageNo) {
            this.page.pageNo = pageNo;
            this.getCollectiondatatwo();
        },
        currtentPageChange(pageSize) {
            this.page.pageSize = pageSize;
            this.getCollectiondatatwo();
        },
        onChildPageChange(pageNo) {
            this.pageChild.pageNo = pageNo;
            this.getCollectiondata();
        },
        currtentChildPageChange(pageSize) {
            this.pageChild.pageSize = pageSize;
            this.getCollectiondata();
        },
        //tab切换
        handleTabClick() {},
        //Order List
        hanldeDown(row) {},
        //Recharge list
        hanldeApply(row) {},
        //List of login duration and times
        hanldeDetails(row) {
            this.timesDetailDialog = true;
        },
        ResetHistory() {
            this.grouid = '';
            this.value = '';
            this.CategoryVal = '';
            this.value1 = [];
        },
        searchHistory() {
            this.getCollectiondata();
        },
    },
};
</script>
<style scoped>
/* 日历大小 */
.el-range-editor.el-input__inner {
    width: 224px !important;
    padding: 3px 3px;
}

.el-date-editor /deep/ .el-range__icon {
    font-size: 14px;
    margin-left: -5px;
    color: #c0c4cc;
    /* float: left; */
    line-height: 32px;
    position: relative;
    left: 47%;
}

.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}

.country_box {
    margin-top: 60px;
}

.country_box .title {
    margin-bottom: 0;
}

.history_box {
    margin-top: 22px;
}

.title {
    margin-bottom: 20px;
}

.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}

.title_txt img {
    width: 20px;
    margin-right: 8px;
}

.c_d_buttom {
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 0;
}

.btn_blue {
    width: 140px;
    height: 40px;
    background: #1290c9;
    border-radius: 12px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
}

.btn_blu {
    margin-right: 20px;
}

.btn_blue img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
}

.his_sel_box .sel_box /deep/ .el-input__inner {
    border-color: #8497ab;
    border-radius: 8px;
}

.his_sel_box .sel_box /deep/ .el-select .el-icon-arrow-up:before {
    color: #8497ab;
    font-weight: 500;
}

.das_top_cont {
    /* margin-top: 50px; */
}

.das_top_item {
  
 
  padding: 20px;
  border-radius: 14px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
}

.das_top_item .item_img {
  width: 40px;
  height: 40px;
  line-height: 48px;
  text-align: center;
  border-radius: 50%;
}

.das_top_item .color1 {
    background: #1290c9;
}

.das_top_item .color2 {
    background: #f56b6b;
}

.das_top_item .color3 {
    background: #55b419;
}

.das_top_item .color4 {
    background: #ff7600;
}

.das_top_item .item_img img {
    width: 18px;
    height: 18px;
}

.das_top_item .item_title {
    font-size: 18px;
    color: #022955;
    margin: 10px 0 10px;
    text-align: left;
}
.das_top_item .item_group {
margin-left: 20px;
    font-size: 14px;
    width: 240px;
}

.das_top_item .item_lab {
    display: block;
    color: #8497ab;
    /* margin-bottom: 8px; */
    /* width: 180px; */
    margin-top: 5px;

}.item_txt{
    font-size: 18px;
}

.top_tab {
    margin-top: 40px;
}

.result_tab {
    padding: 20px;
}

.flex__box {
    display: flex;
    align-items: center;
}

.oper_down img {
    width: 18px;
    height: 16px;
}

.detail_pop_list {
    margin: 40px auto 0;
}

.detail_pop_title_box {
    border-bottom: 1px solid #e7eaed;
    padding-bottom: 10px;
}

.detail_pop_item {
    border-bottom: 1px solid #e7eaed;
    padding: 15px 0;
}

.detail_pop_txt,
.detail_pop_title {
    width: 33.3%;
    text-align: center;
    font-size: 16px;
}

.detail_pop_title {
    color: #8497ab;
}

.detail_pop_txt {
    color: #022955;
}

.txt_right {
    text-align: right;
    margin-top: 15px;
}

.sel_box {
    margin-right: 15px;
}

.create_item_lab {
    font-size: 16px;
    font-family: 'Arial Bold';
    color: #022955;
    margin-bottom: 8px;
}

.create_item_num {
    font-size: 14px;
    font-family: 'Arial Bold';
    color: #8497ab;
}

.create_inpt {
    width: 100%;
    height: 46px;
    background: #ffffff;
    border-radius: 12px;
    border: 1px solid #e7eaed;
    font-size: 16px;
    padding: 13px 20px;
    margin-bottom: 20px;
}

.btn_comp_box {
    text-align: right;
}

.check_item {
    position: relative;
    max-height: 540px;
    overflow: auto;
    background: #f2f5f9;
    border-radius: 8px;
    border: 1px solid #e7eaed;
    padding: 20px 20px 0;
    margin-bottom: 10px;
}

.check_item::after {
    content: '';
    position: absolute;
    left: 48%;
    top: 20px;
    bottom: 20px;
    width: 2px;
    background-color: #e7eaed;
}

.company_b_txt {
    font-size: 12px;
    color: #8497ab;
    line-height: 16px;
}

.company_box .el-checkbox {
    width: 50%;
    margin-bottom: 20px;
    margin-right: 0;
}

.company_box .comp_top_check .el-checkbox {
    margin-bottom: 10px;
    margin-left: 20px;
}

.company_box /deep/ .el-checkbox__input {
    float: left;
}
.company_box /deep/ .el-checkbox__label {
    color: #022955;
    width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 16px;
    float: left;
    padding-top: 2px;
}

.company_box /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #022955;
}

.company_box /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.company_box /deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #022955;
    border-color: #022955;
}

.company_box /deep/ .el-checkbox__inner {
    width: 20px;
    height: 20px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #8497ab;
}

.company_box /deep/ .el-checkbox__inner::after {
    height: 10px;
    left: 6px;
    top: 1px;
    width: 5px;
    border-width: 2px;
}

.comp_search {
    position: relative;
    width: 300px;
    margin-bottom: 10px;
}

.comp_search .coll_search_btn {
    top: 7px;
}
.table_box /deep/ .el-table__expand-icon {
    display: block;
}
.el-button.is-disabled {
    background-color: #c0c4cc;
}
.title-input /deep/ .el-input__inner {
    border-radius: 15px;
}
</style>
